$(function() {
    $('.menu-item').on('mouseover',function() {
        $(this).find('.child-menu').show();
    });
    $('.menu-item').on('mouseout',function(){
        $(this).find('.child-menu').hide();
    });

    $('.tabs-list .tabs-title').on('click',function () {
        $('.tabs-list .tabs-title').removeClass('active');
        $(this).addClass('active');
        var index = $(this).parent('li').index();
        $('.tabs-list .tab-content').removeClass('active');
        $('.tabs-list .tab-content').eq(index).addClass('active');
    });


    var mySwiper = new Swiper('.article-swiper', {
        autoplay: 3000,//可选选项，自动滑动
        loop: true,
        autoplayDisableOnInteraction: false,//如果设置为false，用户操作swiper之后自动切换不会停止
        autoHeight: true,
        prevButton:'.swiper-button-prev',
        nextButton:'.swiper-button-next',
        preventClicks: false,
        pagination: '.swiper-pagination',
        paginationClickable: '.swiper-pagination',
    });

    $('#submit').on('click',function() {
        var keyword = $('.search-input[type=search]').val();
        if(keyword){
            location.href = "/home/search/index.html?keyword="+ keyword;
        }else{
            return false;
        }
    });
    $('.vertical-list-box .item').on('mouseover',function () {
        $(this).find('img').css('transform','scale(1.2)');
    });
    $('.vertical-list-box .item').on('mouseout',function () {
        $(this).find('img').css('transform','scale(1)');
    });

    $('header .icon-wechat').on('mouseover', function () {
        $('.qr-code-box').show();
    });
    $('header .icon-wechat').on('mouseout', function () {
        $('.qr-code-box').hide();
    });

    if($('.vertical-list-box li').length > 3){
        $('.vertical-list-box li').addClass('add-li');
    }
})

function addSpanSearch(keyword,searchClass,spanClass) {
    $(searchClass).each(function () {
        var $dom = $(this);
        if ($dom.html().indexOf(keyword) !== -1){
            let replacedStr = $dom.html().replace(new RegExp(keyword,'g'),'<span class="'+spanClass+'">' + keyword + '</span>');
            $dom.html(replacedStr);
        }
    });
}